.loading-circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loading-circle {
  border: 8px solid #208080;
  border-top: 8px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
